/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { Link, useLocation } from 'react-router-dom';
import useCurrentUserRole from '../../hooks/useCurrentUserRole';

// eslint-disable-next-line no-unused-vars
const SidebarItem = ({
	item = {},
	path,
	text,
	isActive,
	onClick,
	icon,
	narrowSidebar,
	menu = true,
}) => {
	const location = useLocation();
	const { state: currentUserRole } = useCurrentUserRole();

	if (item?.roles && !item?.roles.includes(currentUserRole)) {
		return null;
	}

	return (
		<div className="w-full">
			<Link
				to={path}
				type="button"
				className={`flex w-full items-center rounded-md py-2 font-Montserrat font-medium transition-colors duration-300 ease-in-out hover:bg-[#E8F5FC] hover:text-black focus:outline-none ${
					narrowSidebar ? 'justify-center px-0' : `justify-normal ${menu ? 'px-4' : 'px-1'}`
				} ${
					location.pathname === path ||
					(location.pathname.search('jobs') === 1 &&
						text === 'Job Circular' &&
						(location.pathname.search('applicants') === -1 ||
							location.pathname.search('/jobs/applicants/') !== -1)) ||
					(text === 'Applicants' && location.pathname.search('/jobs/applicants-profile') !== -1) ||
					(text === 'Subscriptions' && location.pathname.search('/subscription-plans') !== -1)
						? 'bg-[#F6F8FC] font-semibold text-black'
						: item.title === 'Help Center' || item.title === 'Privacy Policy'
						? 'bg-white font-semibold text-black'
						: 'bg-white font-semibold text-[#5F6D83]'
				}`}
				onClick={onClick}
			>
				{icon && (
					<div
						className={`flex h-[36px] w-[36px] items-center justify-center rounded-full p-2 ${
							narrowSidebar ? 'mx-0' : menu ? 'mx-4' : 'mx-1'
						}`}
					>
						<img src={icon} alt="" className="h-[24px] w-[24px]" />
					</div>
				)}
				<span className={narrowSidebar ? 'hidden' : ''}>{text}</span>
			</Link>
		</div>
	);
};
export default SidebarItem;
