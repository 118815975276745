/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import { useQuery } from '@tanstack/react-query';
import { formatDistanceToNow } from 'date-fns';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { HiDotsVertical } from 'react-icons/hi';
import { Link } from 'react-router-dom';

import { getActivities } from '../../apis/activity-apis';
import { appUrl } from '../../apis/config/baseURL';
import { getJobs } from '../../apis/jobs/jobApis';
import { getTopApplicants } from '../../apis/statistics/statisticsApis';
import openGraphImage from '../../assets/images/open-graph-image.png';
import Star from '../../assets/svgs/StartIcon';
import Spinner from '../../components/_common/loaders/Spinner';
import { ApplicantsBanner } from '../../components/molecules/ApplicantsBanner';
import JobsCard from '../../components/molecules/JobsCard';
import StatisticsCards from '../../components/organisms/StatisticsCards';
import { TalentPoolSlider } from '../../components/templates/TalentPoolSlider';
import { DropDownData } from '../../data/DropDowndata';

const NewDashboardPage = ({
	jobType = 'active',
	handleConfirmationModal,
	deleteID,
	handleShareJobModal,
	handleCloseJob,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState('Last 30 days');
	const [filterParams, setFilterParams] = useState({
		sort: 'createdAt',
		location: '',
	});
	const { sort, location } = filterParams;

	const { data: jobs } = useQuery({
		queryKey: ['dashboard/jobs', jobType, sort, location],
		queryFn: () => getJobs(jobType, sort, location),
	});

	const { data: activities, isLoading } = useQuery({
		queryKey: ['dashboard/activities'],
		queryFn: getActivities,
	});

	// Add query for top applicants
	const { data: topApplicantsData } = useQuery({
		queryKey: ['dashboard/top-applicants'],
		queryFn: getTopApplicants,
	});

	const buttonText = 'Start hiring';
	const heading = 'Get your hiring process off to a flying start with HrClerks.';
	const para =
		"Post jobs, get candidates and manage applicants all in one place. Let's get started.";

	const options = ['Last 30 days', 'Last 15 days', 'Last 7 days'];

	const recentActivity = [
		{
			person: 'Devon Lane',
			action: 'applied for Software Engineer',
			time: '10 mins ago',
		},
		{
			person: 'Jacob Jones',
			action: 'applied for Software Engineer',
			time: '10 mins ago',
		},
		{
			person: 'Henry Smith',
			action: 'was labeled Not-fit for Frontend Developer',
			time: '10 mins ago',
		},
		{
			person: 'Jacob Jones',
			action: 'was rated 4.5',
			time: '2 days ago',
		},
		{
			person: 'Jacob Jones',
			action: 'applied for Software Engineer',
			time: 'Nov 02, 2024',
		},
	];

	// Convert selected days text to number
	const getDaysFromSelection = (selection) => {
		switch (selection) {
			case 'Last 15 days':
				return 15;
			case 'Last 7 days':
				return 7;
			default:
				return 30;
		}
	};

	console.log({ activities, isLoading });

	return (
		<>
			<Helmet>
				<link rel="canonical" href={appUrl} />
				<title>Dashboard | Elevate your hiring process with HrClerks ATS</title>
				<meta
					name="description"
					content="Dashboard | Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				{/* Open Graph */}
				<meta property="article:author" content="HrClerks Talent Solution" />
				<meta property="article:publisher" content={appUrl} />
				<meta property="og:site_name" content="hrclerks.com" />
				<meta name="og:title" content="Dashboard | Elevate your hiring process with HrClerks ATS" />
				<meta
					name="og:description"
					content="Dashboard | Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				<meta name="og:image" content={openGraphImage} />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta name="og:type" content="website" />
				<meta name="og:url" content={appUrl} />
				{/* Twitter */}
				<meta property="article:author" content="HrClerks Talent Solution" />
				<meta property="article:publisher" content={appUrl} />
				<meta property="twitter:site_name" content="hrclerks.com" />
				<meta
					name="twitter:title"
					content="Dashboard | Elevate your hiring process with HrClerks ATS"
				/>
				<meta
					name="twitter:description"
					content="Dashboard | Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				<meta name="twitter:image" content={openGraphImage} />
				<meta property="twitter:image:width" content="1200" />
				<meta property="twitter:image:height" content="630" />
				<meta name="twitter:url" content={appUrl} />
				<meta
					name="twitter:card"
					content="Dashboard | Elevate your hiring process with HrClerks ATS"
				/>
			</Helmet>
			<div className="mx-1.5 my-6 min-h-[calc(100vh-180px)] rounded-xl bg-white p-3 pb-0 pt-5 drop-shadow-xl min-[500px]:mx-6 min-[500px]:mt-[32.5px] sm:p-6 md:pb-20">
				<ApplicantsBanner buttonText={buttonText} heading={heading} para={para} />

				{/* Quick Stats */}
				<div className="mx-auto py-8">
					<div className="mb-6 flex items-center justify-between px-6">
						<h2 className="text-xl font-semibold text-gray-900">Quick Stats</h2>
						<div className="relative">
							<button
								onClick={() => setIsOpen(!isOpen)}
								className="flex items-center gap-2 rounded-lg border border-[#A6AFBD] bg-white px-4 py-2 font-semibold text-[#1098FF]"
							>
								{selected}
								<svg
									className={`h-4 w-4 transition-transform ${isOpen ? 'rotate-180' : ''}`}
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M19 9l-7 7-7-7"
									/>
								</svg>
							</button>

							{isOpen && (
								<div className="absolute right-0 mt-2 w-48 rounded-lg border border-gray-200 bg-white p-2 font-semibold text-[#1098FF] shadow-xl">
									{options.map((option) => (
										<button
											key={option}
											onClick={() => {
												setSelected(option);
												setIsOpen(false);
											}}
											className={`w-full rounded-lg px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-400 ${
												selected === option ? 'text-blue-400' : 'text-gray-300'
											}`}
										>
											{option}
										</button>
									))}
								</div>
							)}
						</div>
					</div>
				</div>
				<StatisticsCards selectedDays={getDaysFromSelection(selected)} />

				<div className="mb-6 mt-10 flex items-center justify-between px-6">
					<h2 className="text-xl font-semibold text-gray-900">Active Jobs</h2>
					<Link
						to="/jobs"
						className="text-medium rounded-md border border-gray-300 px-2 py-1 font-semibold text-[#1098FF] hover:text-blue-600"
					>
						View All
					</Link>
				</div>

				<TalentPoolSlider>
					{jobs?.data?.jobs?.map((job) => (
						<JobsCard
							key={job?.id}
							dropIcon={<HiDotsVertical />}
							DropDownData={DropDownData}
							data={[job]}
							handleConfirmationModal={handleConfirmationModal}
							handleShareJobModal={handleShareJobModal}
							handleCloseJob={handleCloseJob}
							fromDashboard
						/>
					))}
				</TalentPoolSlider>

				<div className="mt-6 grid gap-6 md:grid-cols-2">
					{/* Top Applicants Card */}
					<div className="bg-card text-card-foreground rounded-lg border bg-[#F6F9FC] p-6 shadow-md">
						<h2 className="mb-6 text-2xl font-bold">Top Applicants</h2>
						<div className="space-y-0">
							{topApplicantsData?.data?.map((applicant) => (
								<div
									key={applicant.id}
									className="flex cursor-pointer items-center justify-between border-b border-[#D3D6DF] bg-white p-4 hover:border-none hover:bg-[#F4FAFF] hover:shadow-md"
								>
									<div className="flex items-center gap-3">
										<img
											src={applicant.profile}
											alt={`${applicant.firstName} ${applicant.lastName}`}
											className="h-10 w-10 rounded-full"
										/>
										<div className="space-y-1">
											<h3 className="mr-3 text-base font-medium">
												{applicant.firstName} {applicant.lastName}
											</h3>
										</div>
										<p className="text-sm text-gray-500">{applicant.email}</p>
									</div>
									<div className="flex gap-1">
										<Star />
										<span className="text-sm">{applicant.rating}/5</span>
									</div>
								</div>
							))}
						</div>
					</div>

					{/* Recent Activity Card */}
					<div className="bg-card text-card-foreground rounded-lg border bg-[#F6F9FC] p-6 shadow-md">
						<h2 className="mb-6 text-2xl font-bold">Recent Activity</h2>

						<div className="space-y-0">
							{isLoading && <Spinner />}

							{!isLoading && activities?.data?.length === 0 && <p>No activities found</p>}

							{!isLoading &&
								activities?.data?.length &&
								activities?.data?.map((activity, index) => (
									<div
										key={index}
										className="flex cursor-pointer border-b border-[#D3D6DF] bg-white px-4 py-5 hover:border-none hover:bg-[#F4FAFF] hover:shadow-md"
									>
										<div className="flex-1 pr-4">
											<p className="break-words text-base font-medium">{activity.title}</p>
										</div>

										{/* <p className="break-words text-base">
											<span className="mr-1 font-medium">{activity.person}</span>{' '}
											<span className="text-gray-400">{activity.action}</span>
										</p> */}

										<span className="flex-shrink-0 text-sm text-gray-500">
											{formatDistanceToNow(new Date(activity.createdAt), { addSuffix: true })}
										</span>
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default NewDashboardPage;
