/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

export const TalentPoolSlider = ({
	handleConfirmPoolModal = null,
	poolJobListData = {},
	children,
}) => {
	const sliderRef = useRef(null);

	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		swipeToSlide: true,
		draggable: true,
		arrows: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	useEffect(() => {
		const handleWheel = (e) => {
			if (!sliderRef.current) return;

			const slider = sliderRef.current;
			const slideCount = slider.props.children.length;
			const slidesToShow = slider.state.breakpoint
				? settings.responsive.find((x) => x.breakpoint >= window.innerWidth)?.settings
						.slidesToShow || settings.slidesToShow
				: settings.slidesToShow;

			const { currentSlide } = slider.state;
			const isAtStart = currentSlide === 0;
			const isAtEnd = currentSlide >= slideCount - slidesToShow;

			// If we're at either end of the slider, allow normal page scrolling
			if ((isAtStart && e.deltaY < 0) || (isAtEnd && e.deltaY > 0)) {
				return;
			}

			// Prevent page scrolling while in slider range
			e.preventDefault();

			// Determine scroll direction and move slider
			if (e.deltaY > 0) {
				slider.slickNext();
			} else {
				slider.slickPrev();
			}
		};

		// Add wheel event listener to slider container
		const sliderElement = document.querySelector('.talent-pool-slider');
		if (sliderElement) {
			sliderElement.addEventListener('wheel', handleWheel, { passive: false });
		}

		return () => {
			if (sliderElement) {
				sliderElement.removeEventListener('wheel', handleWheel);
			}
		};
	}, []);

	return (
		<div className="talent-pool-slider">
			<Slider ref={sliderRef} {...settings}>
				{children}
			</Slider>
		</div>
	);
};
