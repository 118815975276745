import api from '../config/axiosInstance';

const statisticsApis = {
	async getTotalJobs(days = 30) {
		try {
			const response = await api.get(`/statistics/total/jobs`, {
				params: { days },
			});
			return response?.data;
		} catch (error) {
			return error;
		}
	},
	async getTotalApplicants(days = 30) {
		try {
			const response = await api.get(`/statistics/total/applications`, {
				params: { days },
			});
			return response?.data;
		} catch (error) {
			return error;
		}
	},
	async getTotalFitApplicants(days = 30, type = '') {
		try {
			const response = await api.get(`/statistics/total/fit-applicants`, {
				params: { days, type },
			});
			return response?.data;
		} catch (error) {
			return error;
		}
	},

	async getTopApplicants() {
		try {
			const response = await api.get(`/statistics/top-applicants`);
			return response?.data;
		} catch (error) {
			return error;
		}
	},
};

export const { getTotalJobs, getTotalApplicants, getTotalFitApplicants, getTopApplicants } =
	statisticsApis;

export default statisticsApis;
