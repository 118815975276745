/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import { useQuery } from '@tanstack/react-query';
import {
	getTotalApplicants,
	getTotalFitApplicants,
	getTotalJobs,
} from '../../apis/statistics/statisticsApis';
import StatArrowDown from '../../assets/svgs/StatArrowDown';
import StatArrowUp from '../../assets/svgs/StatArrowUp';

const Card = ({ title, value, change, isIncrease, color, suffix }) => {
	const getTextColor = () => {
		switch (color) {
			case 'green':
				return 'text-emerald-500';
			case 'yellow':
				return 'text-amber-500';
			case 'red':
				return 'text-red';
			default:
				return 'text-gray-900';
		}
	};

	return (
		<div className="cursor-pointer rounded-lg bg-[#F7F9FD] p-6 shadow-md hover:bg-[#F4FAFF] hover:shadow-lg">
			<h3 className={`mb-4 text-sm font-medium ${getTextColor()}`}>{title}</h3>
			<div className="flex flex-col items-baseline gap-2">
				<span className="text-4xl font-semibold text-gray-900">{value}</span>
				<div className="flex items-center gap-1">
					{isIncrease ? (
						<StatArrowUp className="h-6 w-6 text-emerald-500" />
					) : (
						<StatArrowDown className="text-red-500 h-6 w-6" />
					)}
					<span className={`text-md ${isIncrease ? 'text-emerald-500' : 'text-red'}`}>
						{Math.abs(change).toFixed(1)}%{suffix && ` ${suffix}`}
					</span>
				</div>
			</div>
		</div>
	);
};

const StatisticsCards = ({ selectedDays = 30 }) => {
	// Queries for different statistics
	const { data: totalApplicants } = useQuery({
		queryKey: ['totalApplicants', selectedDays],
		queryFn: () => getTotalApplicants(selectedDays),
	});

	const { data: totalJobs } = useQuery({
		queryKey: ['totalJobs', selectedDays],
		queryFn: () => getTotalJobs(selectedDays),
	});

	const { data: goodFitApplicants } = useQuery({
		queryKey: ['fitApplicants', selectedDays, 'good_fit'],
		queryFn: () => getTotalFitApplicants(selectedDays, 'good_fit'),
	});

	const { data: maybeFitApplicants } = useQuery({
		queryKey: ['fitApplicants', selectedDays, 'may_be'],
		queryFn: () => getTotalFitApplicants(selectedDays, 'may_be'),
	});

	const { data: notFitApplicants } = useQuery({
		queryKey: ['fitApplicants', selectedDays, 'not_fit'],
		queryFn: () => getTotalFitApplicants(selectedDays, 'not_fit'),
	});

	const cardsData = [
		{
			title: 'Total Applicants',
			value: totalApplicants?.data?.count || 0,
			change: totalApplicants?.data?.growth || 0,
			isIncrease: (totalApplicants?.data?.growth || 0) > 0,
			color: 'black',
		},
		{
			title: 'Total Job Posted',
			value: totalJobs?.data?.count || 0,
			change: totalJobs?.data?.growth || 0,
			isIncrease: (totalJobs?.data?.growth || 0) > 0,
			color: 'black',
		},
		{
			title: 'Good Fit Applicants',
			value: goodFitApplicants?.data?.count || 0,
			change: goodFitApplicants?.data?.growth || 0,
			isIncrease: (goodFitApplicants?.data?.growth || 0) > 0,
			color: 'green',
		},
		{
			title: 'Maybe Fit Applicants',
			value: maybeFitApplicants?.data?.count || 0,
			change: maybeFitApplicants?.data?.growth || 0,
			isIncrease: (maybeFitApplicants?.data?.growth || 0) > 0,
			color: 'yellow',
		},
		{
			title: 'Not Fit Applicants',
			value: notFitApplicants?.data?.count || 0,
			change: notFitApplicants?.data?.growth || 0,
			isIncrease: (notFitApplicants?.data?.growth || 0) > 0,
			color: 'red',
			suffix: 'vs last month',
		},
	];

	return (
		<div className="">
			<div className="grid grid-cols-1 gap-4 md:grid-cols-5">
				{cardsData.map((card, index) => (
					<Card key={index} {...card} />
				))}
			</div>
		</div>
	);
};

export default StatisticsCards;
