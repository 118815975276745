const baseURL =
	process.env.NODE_ENV === 'production'
		? 'https://ats-admin.hrclerks.com'
		: 'https://ats-admin.hrclerks.com';

export const appUrl = process.env.REACT_APP_APP_URL || 'https://ats.hrclerks.com';
// export const appUrl = process.env.REACT_APP_APP_URL || 'http://localhost:4000';
// live backend=https://ats-admin.hrclerks.com

export default baseURL;
