import api from './config/axiosInstance';

const activityApis = {
	async getActivities() {
		try {
			const response = await api.get('activities');

			return response?.data;
		} catch (error) {
			return error;
		}
	},
};

export const { getActivities } = activityApis;

export default activityApis;
